import { useState } from "react";
import { shuffle } from "../Configs/Helpers";
import { ProfessionalBySpecialty } from "../Domain";
import { findProfissionalsBySpecialty } from "../HttpClients/SheduleApiClient";

type useProfessionalLoaderResult = {
  data: Array<ProfessionalBySpecialty>;
  loading: boolean;
};

type loadProfissionalsProps = {
  specialtyId: string | number;
};

const useProfissionalsLoader = (): [ (params: loadProfissionalsProps) => void,  () => void,  useProfessionalLoaderResult ] => {
  const [data, setData] = useState<Array<ProfessionalBySpecialty>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  


  async function loadProfissionals({specialtyId}: loadProfissionalsProps) {
    try {
      setLoading(true);
      setData(shuffle(await findProfissionalsBySpecialty({ specialtyId })));
    } finally {
      setLoading(false);
    }
  }

  function resetData() {
    setData([]);
  }

  return [loadProfissionals, resetData, { data, loading }];
};

export default useProfissionalsLoader;

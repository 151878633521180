import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Theme,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { ResponsiblePaymentType } from "../../Domain";

type ResponsiblePaymentFieldProps = {
  sx?: SxProps<Theme>;
};

const ResponsiblePaymentField: React.FC<ResponsiblePaymentFieldProps & RadioGroupProps> = ({ sx = {}, ...radioGroupProps }) => (
  <FormControl component="fieldset" fullWidth sx={sx}>
    <RadioGroup aria-label="responsiblePayment" row {...radioGroupProps}>
      <FormControlLabel
        value={ResponsiblePaymentType.PROPRIO}
        control={<Radio />}
        label="Mesmo Nome acima"
        // defaultValue="PROPRIO"
      />
      <FormControlLabel
        value={ResponsiblePaymentType.TERCEIRO}
        control={<Radio />}
        label="Outra Pessoa"
      />
    </RadioGroup>
  </FormControl>
);

export default ResponsiblePaymentField;

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export enum BillingType {
  BOLETO = "BOLETO",
  PIX = "PIX",
  CREDIT_CARD = "CREDIT_CARD",
  UNDEFINED = "UNDEFINED",
}

export enum ResponsiblePaymentType {
  PROPRIO = "PROPRIO",
  TERCEIRO = "TERCEIRO",
}

export interface Specialty {
  id?: number;
  name?: string;
}

export interface Professional {
  id: number;
  name: string;
  avatarUrl?: string;
  description?: string;
  rqe?: string;
}

export interface Appointment {
  date?: Date | null;
  schedule?: string | null;
  specialty: Specialty | null;
  professional: Professional | null;
  officerId?: number | null;
  amount?: number | null;
}

export interface ProfessionalBySpecialty {
  id: number;
  name: string;

}

export interface AvailableSchedule {
  professional: Professional;
  officerId: number;
  schedules: Array<string>;
}
export interface AvailableScheduleProfLoader {
  id: number;
  name: string;
  avatarUrl: string;
  rqe: string;
}

export interface Customer {
  patient_name?: string;
  patient_cpf?: string;
  phone?: string;
  email?: string;
  responsiblePayment?: string;
  responsible_name?: string;
  responsible_cpf?: string;
  cep?: string;
  address?: string;
  address_number?: string;
  complement?: string;
  district?: string;
  city?: string;
  state?: string;
  observation?: string;
  billingType?: BillingType;
}

export interface CreatePaymentRequest extends Customer {
  billingType?: BillingType;
  sheduleDate?: string;
  sheduleHour?: string;
  professionalId?: number;
  officerId?: number;
  specialtyId?: number;
  amount?: number;
}

export interface CreatePaymentResponse {
  invoiceUrl: string;
}

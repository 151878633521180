import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { AvailableScheduleProfLoader, Professional } from "../../Domain";
import { Avatar, Card, CardContent, Grid, Rating, Typography, } from "@mui/material";


type AvailablesSchedulesCardProps = {
  availableSchedule: AvailableScheduleProfLoader;
  onSelect(professional: Professional): void;
};


const AvailablesSchedulesCard: React.FC<AvailablesSchedulesCardProps> = ({ availableSchedule: { id, name, avatarUrl, rqe }, onSelect, }) => (

  <Card key={id} sx={{ mb: 2 }} onClick={() => onSelect({ id, name, avatarUrl, rqe })}>
    <CardContent>
      <Grid container flexDirection="unset" justifyContent="center" alignItems="center" spacing={1} sx={{cursor:'pointer'}}>
        

          <Grid container flexDirection="row" justifyContent="center" alignItems="center" spacing={1} >
            <Grid item>
              <Avatar alt={name} src={avatarUrl} sx={{ width: 80, height: 80 }} />
            </Grid>
            <Grid item flex={1} >
              <Typography variant="h5" fontWeight="bold" component="div">{name}</Typography>

              {/* <Typography variant="body2" component="div" sx={{ mb: 2 }}>{currencyFormat(rqe)} </Typography> */}
              <Rating name="disabled" value={Number(5)} disabled />
              {/* {
              schedules.map((schedule) => (
                <Chip key={schedule} sx={{ mr: "0.3rem", mb: "0.3rem" }} label={schedule} color="primary" onClick={() => onSelect(schedule)} />
                ))
              } */}
            </Grid>
            <Grid item justifyContent="center" alignItems="center"  sx={{ m: 0 }}>
              <KeyboardArrowRightIcon />
            </Grid>

          </Grid>
  
      </Grid>

    </CardContent>
  </Card>
);

export default AvailablesSchedulesCard;

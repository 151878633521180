import { Card, CardContent, Grid, Skeleton, Typography } from "@mui/material";

const AvailablesSchedulesCardSkeleton: React.FC = () => (
  <Card sx={{ mb: 2 }}>
    <CardContent>
      <Grid container flexDirection="row" justifyContent="center" alignItems="center" spacing={2}>
        <Grid item>
          <Skeleton variant="circular" width={100} height={100} />
        </Grid>
        <Grid item flex={1}>
          <Typography variant="h5" fontWeight="bold" component="div">
            <Skeleton variant="text" />
          </Typography>
          <Typography variant="body2">
            <Skeleton variant="text" />
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
    <Grid container sx={{ p: 2, pt: 0 }}>
      {Array.of(1, 2).map((schedule) => (
        <Grid key={schedule} item>
          <Skeleton variant="rectangular" width={80} height={20} sx={{ m: 1 }}/>
        </Grid>
      ))}
    </Grid>
  </Card>
);

export default AvailablesSchedulesCardSkeleton;

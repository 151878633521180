import React, { useState } from "react";
import { Appointment, Professional } from "../../Domain";
import { BillingType } from "../../Domain";

import { Box } from "@mui/system";

import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import styled from "@mui/system/styled";
import {
  Step,
  Stepper,
  Typography,
  StepContent,
  Button,
  FormControl,
  MenuItem,
  Alert,
  InputAdornment,
  Grid,
} from "@mui/material";

import logoCasule from "../../Assets/Images/logo-casule.png";

import useSpecialtyLoader from "../../Hooks/useSpecialtyLoader";
import useCreatePayment from "../../Hooks/useCreatePayment";
import useProfissionalsLoader from "../../Hooks/useProfissionalsLoader";

import AvailablesSchedulesCard, {
  AvailablesSchedulesCardSkeleton,
} from "../../Components/AvailablesSchedulesCard";
import CustomerForm from "../../Components/CustomerForm";
import { Footer } from "../../Components/Footer/Footer";
import { CustomStepLabel, CustomTextField } from "../../Configs/CustomStyle";
import { useParams } from "react-router-dom";
import { removeMask } from "../../Configs/Helpers";

const regex = new RegExp(/[^0-9]/);

const Header = styled(Box)({
  padding: "2rem",
  display: "flex",
  justifyContent: "center",
});

interface iParams {
  [key: string]: any;
}

const SchedulePageSeq: React.FC = () => {
  const params: iParams = useParams();
  const { data: specialties } = useSpecialtyLoader();

  const [
    findProfissionals_,
    resetData,
    { data: availablesSchedules, loading: loadingAvailableShedule },
  ] = useProfissionalsLoader();
  const [createPayment, { data, errorMessage }] = useCreatePayment();

  const [activeStep, setActiveStep] = useState(0);
  const [alertNoData, setAlertNoData] = useState(false);
  // const [btnActive, setBtnActive] = useState(false);
  const [appointment, setAppointment] = useState<Appointment>({
    date: null,
    schedule: null,
    specialty: null,
    professional: null,
    officerId: null,
    amount: null,
  });
  const lsNoData = [undefined,null,""];

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  function defineSpecialty() {
    if (
      params.specialty !== undefined &&
      params.professional !== undefined &&
      params.amount !== undefined
    ) {
      if (specialties.length > 0) {
        setAppointment({
          ...appointment,
          specialty: specialties.filter(
            ({ id }) => id === parseInt(params.specialty),
          )[0],
        });
        findProfissionals_({ specialtyId: params.specialty });
        handleNext();
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }

  function defineProfessional(availablesSchedules: Professional[]) {
    if (
      params.specialty !== undefined &&
      params.professional !== undefined &&
      params.amount !== undefined
    ) {
      if (availablesSchedules[0] !== undefined) {
        let prof = availablesSchedules.filter(
          ({ id }) => id === parseInt(params.professional),
        )[0];
        setAppointment({ ...appointment, professional: prof });
        handleNext();
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }

  // function defineAmount() {
  //   if (params.specialty !== undefined && params.professional !== undefined && params.amount !== undefined) {
  //     setAppointment({ ...appointment, amount: params.amount });
  //     handleNext();
  //   }
  // }

  // useEffect(() => {
  //   const handlePayment = async () => {
  //     window.open(data?.invoiceUrl, "_blank");
  //   };
  //   data && handlePayment();
  // }, [data]);

  return (
    <Container maxWidth="sm">
      <Header>
        <img src={logoCasule} alt="Casule" width={176} height={69} />
      </Header>

      <Stepper activeStep={activeStep} orientation="vertical" sx={{ pb: 2 }}>
        {/* Step 1 */}
        <Step>
          {!appointment.specialty && defineSpecialty()}
          <CustomStepLabel>
            Selecione a Especialidade
            {appointment.specialty && (
              <Typography variant="body2" color="gray">
                {" "}
                {appointment.specialty?.name}{" "}
              </Typography>
            )}
          </CustomStepLabel>
          <StepContent>
            <FormControl fullWidth>
              <Select
                displayEmpty
                value={appointment.specialty?.id || ""}
                onChange={(event) => {
                  setAppointment({
                    ...appointment,
                    specialty: specialties.filter(
                      ({ id }) => id === event.target.value,
                    )[0],
                  });
                  findProfissionals_({ specialtyId: event.target.value });
                  handleNext();
                }}
                sx={{ mb: 2, backgroundColor: "#FFFFFF" }}
              >
                <MenuItem disabled value={""}>
                  Selecione
                </MenuItem>
                {specialties.map(
                  (specialty) =>
                    specialty.id !== 188 && (
                      <MenuItem key={specialty.id} value={specialty.id}>
                        {specialty.name}
                      </MenuItem>
                    ),
                )}
              </Select>
            </FormControl>
          </StepContent>
        </Step>

        {/* Step 2 */}
        <Step>
          <CustomStepLabel>
            Selecione o Profissional
            {appointment.professional ? (
              <Typography
                variant="body2"
                color="gray"
              >{`${appointment.professional.name}`}</Typography>
            ) : (
              appointment.specialty && (
                <Typography
                  variant="body2"
                  color="gray"
                >{`Toque para escolher o profissional desejado`}</Typography>
              )
            )}
          </CustomStepLabel>
          <StepContent>
            {!appointment.professional ? (
              defineProfessional(availablesSchedules)
            ) : (
              <></>
            )}
            <FormControl fullWidth>
              {loadingAvailableShedule &&
                Array.of(1).map((_, index) => (
                  <AvailablesSchedulesCardSkeleton key={index} />
                ))}

              {!loadingAvailableShedule &&
                availablesSchedules.map((availableSchedule: any) => (
                  <AvailablesSchedulesCard
                    key={availableSchedule.id}
                    availableSchedule={availableSchedule}
                    onSelect={(professional) => {
                      setAppointment({
                        ...appointment,
                        professional: professional,
                        // officerId: availableSchedule.officerId,
                      });
                      handleNext();
                    }}
                  />
                ))}
            </FormControl>

            <Button
              variant="outlined"
              color="inherit"
              size="large"
              onClick={() => {
                setAppointment({ ...appointment, specialty: null });
                handleBack();
                resetData();
              }}
            >
              voltar
            </Button>
          </StepContent>
        </Step>

        {/* Step 3 */}
        <Step>
          <CustomStepLabel>
            Valor a pagar
            {appointment.amount && (
              <Typography variant="body2" color="gray">
                R${`${appointment.amount}`}
              </Typography>
            )}
          </CustomStepLabel>
          <StepContent>
            {errorMessage !== undefined && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            )}
            {/* {!appointment.amount ? defineAmount() : null} */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <FormControl fullWidth>
                <CustomTextField
                  label="Descrição"
                  name="descricao"
                  value={appointment.specialty?.name}
                  disabled={true}
                  sx={{ mb: 2 }}
                />
              </FormControl>
              <FormControl fullWidth>
                <CustomTextField
                  label="Valor"
                  sx={{ mb: 2 }}
                  name="schedule-value"
                  id="schedule-value"
                  InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                  defaultValue={appointment.professional?.rqe}
                  onChange={(e) => {
                    let currency = e.target.value.replace(regex, "");
                    e.target.value = currency;
                  }}
                />
              </FormControl>
            </Box>

            {/* {btnActive && */}
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              onClick={() => {
                setAppointment({ ...appointment, professional: null });
                handleBack();
              }}
              sx={{ mr: 1 }}
            >
              voltar
            </Button>
            {/* } */}

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                const scheduleValue = document.getElementById("schedule-value") as HTMLInputElement;
                setAppointment({
                  ...appointment,
                  amount: Number(scheduleValue.value),
                });
                handleNext();
              }}
              sx={{ width: "9.5rem" }}
            >
              Próximo
            </Button>
          </StepContent>
        </Step>

        {/* Step 4 */}
        <Step>
          <CustomStepLabel>Dados do Paciente</CustomStepLabel>
          <StepContent>
            {errorMessage !== undefined && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            )}

            <CustomerForm
              onBack={() => handleBack()}
              onSubmit={async (customer) => {
                customer.email = customer.email?.toLowerCase().trim();
                customer.patient_cpf = removeMask(customer.patient_cpf);
                customer.cep = removeMask(customer.cep);
                customer.responsible_cpf = removeMask(customer.responsible_cpf);
                
                if (
                  lsNoData.includes(customer.address) ||
                  lsNoData.includes(customer.address_number) ||
                  lsNoData.includes(customer.cep) ||
                  lsNoData.includes(customer.city) ||
                  lsNoData.includes(customer.district) ||
                  lsNoData.includes(customer.email) ||
                  lsNoData.includes(customer.patient_cpf) ||
                  lsNoData.includes(customer.patient_name) ||
                  lsNoData.includes(customer.phone) ||
                  lsNoData.includes(customer.responsiblePayment) ||
                  lsNoData.includes(customer.responsible_cpf) ||
                  lsNoData.includes(customer.responsible_name) ||
                  lsNoData.includes(customer.state)
                ) {
                  setAlertNoData(true)
                } else {
                  setAlertNoData(false)
                  await createPayment({
                    ...customer,
                    professionalId: appointment.professional!.id,
                    specialtyId: appointment.specialty!.id,
                    amount: appointment.amount!,
                    billingType: BillingType.PIX,
                  });
                  handleNext();
                }

                // console.log({
                //     ...customer,
                //     professionalId: appointment.professional!.id,
                //     specialtyId: appointment.specialty!.id,
                //     amount: appointment.amount!,
                //     billingType: BillingType.PIX,
                //   });

              }}
            />
            {
              alertNoData && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  Favor preencher todos os campos obrigatórios (marcados com *)
                </Alert>
              )
            }
            {errorMessage !== undefined && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
          </StepContent>
        </Step>

        {/* Step 5 */}
        <Step>
          <CustomStepLabel>
            Finalizar Pagamento
            {data?.invoiceUrl && (
              <Typography variant="body2" color="gray">
                {/* Ao finalizar você será redirecionado para a pagina para concluir
                o pagamento. Ou pode tocar no botão "Reabrir Página de
                Pagamento". */}
                Para poder visualizar a página de pagamento, toque no botão "Abrir Página de Pagamento"
                e você será redirecionado para a página.
              </Typography>
            )}
          </CustomStepLabel>

          <StepContent>
            <Grid
              container
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    window.open(data?.invoiceUrl, "_blank");
                  }}
                  sx={{ width: "18rem", fontSize: "14px", mb: 2 }}
                >
                  Abrir Página de pagamento{" "}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    window.location.reload();
                  }}
                  sx={{ width: "18rem" }}
                >
                  {" "}
                  Reiniciar o processo{" "}
                </Button>
              </Grid>
            </Grid>
          </StepContent>
        </Step>
      </Stepper>

      <Footer />
    </Container>
  );
};
export default SchedulePageSeq;

import GppGoodIconOutlined from '@mui/icons-material/GppGoodOutlined';
import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";

export function Footer() {
  return(
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", mb: 5 }}>
          <GppGoodIconOutlined sx={{ mr: 1, fontSize: "1.2rem", color:'#573353'}} />
          <Typography variant="caption" sx={{color:'#573353'}}>Ambiente Seguro e Protegido.</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", mb: 5 }}>
          <Typography variant="caption" sx={{color:'#573353', m: 0, p: 0 }}> Ficou alguma dúvida? Clique no link abaixo </Typography>
          <Typography variant="caption" sx={{color:'#573353'}} color="gray">
            <Link href="https://casule.com/redireciona-whatsapp/" target="_blank" underline="always">Suporte ao Cliente - Whatsapp</Link>
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 5, color:'#573353' }}>
          <Typography variant="caption" sx={{color:'#573353'}}>
            <Link href="#" target="_blank" underline="always" sx={{ color: '#573353' }}> Termos de Serviço </Link>
            e
            <Link href="#" target="_blank" underline="always" sx={{ color: '#573353' }}> Política de Privacidade </Link>
          </Typography>

        </Box>
      </Box>
  )
}
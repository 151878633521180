import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Theme,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { BillingType } from "../../Domain";

type BillingTypeFieldProps = {
  sx?: SxProps<Theme>;
};

const BillingTypeField: React.FC<BillingTypeFieldProps & RadioGroupProps> = ({
  sx = {},
  ...radioGroupProps
}) => (
  <FormControl component="fieldset" fullWidth sx={sx}>
    <FormLabel component="legend">Forma de pagamento</FormLabel>
    <RadioGroup aria-label="billingType" row {...radioGroupProps} >
      {/* <FormControlLabel
        value={BillingType.CREDIT_CARD}
        control={<Radio />}
        label="Cartão de Crédito"
        disabled={true}
      /> */}
      <FormControlLabel
        value={BillingType.PIX}
        defaultValue="PIX"
        disabled={true}
        control={<Radio />}
        label="Pix"
        checked={true}
      />
      {/* <FormControlLabel
        value={BillingType.PIX}
        defaultValue="PIX"
        disabled={false}
        control={<Radio />}
        label="Pix"
      /> */}
    </RadioGroup>
  </FormControl>
);

export default BillingTypeField;

import { useState } from "react";
import { CreatePaymentRequest, CreatePaymentResponse } from "../Domain";
import { createPayment } from "../HttpClients/SheduleApiClient";

const useCreatePayment = (): [
  (payment: CreatePaymentRequest) => Promise<void>,
  {
    data: CreatePaymentResponse | undefined;
    loading: boolean;
    errorMessage: string | undefined;
  }
] => {
  const [data, setData] = useState<CreatePaymentResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  const create = async (payment: CreatePaymentRequest) => {
    try {
      setLoading(true);
      
      setData(await createPayment(payment));
    } catch (error: any) {
      setErrorMessage(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return [create, { data, loading, errorMessage }];
};

export default useCreatePayment;


import { ThemeProvider } from "@mui/material/styles";
import theme from "./Configs/Theme";
import { Box } from "@mui/material";
import { Router } from "./Router";
import { CookiesProvider } from "react-cookie";
import CookieBanner from 'react-cookie-banner';

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <CookiesProvider>
      <CookieBanner
        message="Para uma melhor experiencia, este site utiliza cookies."
        onAccept={() => { }}
        cookie="user-has-accepted-cookies" 
        buttonMessage="Aceitar"
        styles={{
          banner: { backgroundColor: '#FDA75880' },
          message: { fontSize: '0.90rem', color: '#573353', fontFamily: 'Roboto' },
          button: { backgroundColor: '#FDA758', color: '#000' }
        }}
        />
      <Box width="100vw" height="auto" bgcolor={theme.palette.background.default}>
        <Router />
      </Box>
    </CookiesProvider>
  </ThemeProvider >
);

export default App;

export function currencyFormat(val: string) {
  const val_str = new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  }).format(Number(val));

  return val_str;
}

export function shuffle(array: any) {
  var m = array.length, t, i;

  while (m) {
    i = Math.floor(Math.random() * m--);

    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

export type maskOptions = 'cpf_cnpj' | 'tel' | 'cep' | 'login'

export const mask = (type: maskOptions, value: string | undefined) => {

  let strValue = String(value);
  
  if (strValue === null || strValue === undefined) strValue = '';

  switch (type) {

    case 'cpf_cnpj':
      let v = strValue.replace(/\D+/g, '')
      if (v.length > 11) {
        v = v.substring(0, 14)
        v = v.replace(/^(\d{2})(\d)/, "$1.$2")
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
        v = v.replace(/(\d{4})(\d)/, "$1-$2")
      } else {
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
      }
      return v;

    case 'tel':
      if (strValue.length <= 14) {
        const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
        var str = strValue.replace(/[^0-9]/g, "").slice(0, 11);
        return str.replace(regex, "($1) $2-$3");
      } else {
        return strValue;
      }

    case 'cep':
      return strValue
        .replace(/\D+/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1')

    default:
      return strValue;
  }
}

export const removeMask = (value: string | undefined) => {
  if (!value) {
    return value
  }
  let val = value.replace(/\D+/g, '');
  return val;
}

export function maskType(opt: string) {
  switch (opt) {
    case 'cpf_cnpj': return '999.999.999-99' || '99.999.999/9999-99';
    case 'tel': return '(99) 9999-9999';
    case 'cep': return '99999-999';
    default: return '';
  }
}
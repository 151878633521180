import { Specialty } from "../Domain";
import { useEffect, useState } from "react";
import { findSpecialties } from "../HttpClients/SheduleApiClient";

const useSpecialtyLoader = () => {
  const [data, setData] = useState<Specialty[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadSpecialties();
  }, []);

  async function loadSpecialties() {
    try {
      setData(await findSpecialties());
    } finally {
      setLoading(false);
    }
  }

  return { data, loading };
};

export default useSpecialtyLoader;

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export function Phone2Field({ label, name, id, placeholder, required, onChange, value }: any) {
  
  const handleValueChange = (value: any) => {
    onChange({ target: { name, value } });
  };

  return (


    <PhoneInput
      country={'br'}
      onChange={handleValueChange}
      specialLabel={label}
      inputProps={{
        id,
        name,
        required,
        placeholder,
      }}
      inputStyle={{
        width: '100%',
        height: '3.5rem',
        backgroundColor: '#FFFFFF',
        border: '1px solid #bdbdbd',
        
      }}
      containerStyle={{
        width: '100%',
        backgroundColor: '#FFFFFF',
      }}
      buttonStyle={{
        backgroundColor: '#FFFFFF',
        border: '1px solid #bdbdbd',
      }}
      dropdownStyle={{
        // width: '100%',
        // backgroundColor: '#f09',
      }}
      searchStyle={{
        // width: '100%',
        // backgroundColor: '#f09',
      }}

      value={value}


    />

  );
}
import { StepLabel, styled, TextField } from "@mui/material";

export const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#bdbdbd',
  },
  '& .MuiInput-underline:after': {
    backgroundColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
  },
  '& .Mui-disabled': {
    backgroundColor: '#FFFFFF57',
  },
});

export const CustomStepLabel = styled(StepLabel)({

  '& .MuiStepLabel-label.Mui-active': {
    color: '#573353',
  },
  '& .MuiStepLabel-label.Mui-completed': {
    color: '#573353',
  }

});


// 'MuiStepLabel-label Mui-completed css-qivjh0-MuiStepLabel-label'
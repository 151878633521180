import axios from "axios";
import { SHEDULE_API_URL } from "../Configs/env";
import {
  AvailableSchedule,
  CreatePaymentRequest,
  CreatePaymentResponse,
  ProfessionalBySpecialty,
  Specialty,
} from "../Domain";

const HTTP_OK = 200;

export async function findSpecialties(): Promise<Specialty[]> {
  const url = `${SHEDULE_API_URL}/specialties`;
  const { status, statusText, data } = await axios.get(url);

  if (status !== HTTP_OK) {
    throw Error(statusText);
  }

  return data.records;
}

interface FindProfissionalsBySpecialtyParams {
  specialtyId: string | number;
}
interface FindAvailableSchedulesParams {
  date: string;
  specialtyId: number;
  sorted?: boolean;
}

export async function findProfissionalsBySpecialty({specialtyId}: FindProfissionalsBySpecialtyParams): Promise<ProfessionalBySpecialty[]> {
  const url = `${SHEDULE_API_URL}/professionals_by_specialty?specialtyId=${specialtyId}`;
  const { status, statusText, data } = await axios.get(url);

  if (status !== HTTP_OK) {
    throw Error(statusText);
  }

  return data.records;
}

export async function findAvailableSchedules({
  date,
  specialtyId,
  sorted = false,
}: FindAvailableSchedulesParams): Promise<AvailableSchedule[]> {
  const url = `${SHEDULE_API_URL}/available-schedules?date=${date}&specialtyId=${specialtyId}`;
  const { status, statusText, data } = await axios.get(url);

  if (status !== HTTP_OK) {
    throw Error(statusText);
  }

  return sorted ? data.records.sort(() => Math.random() - 0.5) : data.records;
}

export async function createPayment(
  payment: CreatePaymentRequest
): Promise<CreatePaymentResponse> {
  try {
    const url = `${SHEDULE_API_URL}/payments`;
    const { status, data } = await axios.post(url, payment);

    if (status !== HTTP_OK) {
      throw Error(data);
    }

    return data as CreatePaymentResponse;
  } catch (error: any) {
    throw error.response?.data?.message
      ? Error(error.response?.data?.message)
      : error;
  }
}
